import firebase from "firebase/app";
import "firebase/messaging";
import * as constant from "./constant";
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export const getToken = (setTokenFound) => {
  if (!messaging) {
    return null;
  } else {
    return messaging
      .getToken({ vapidKey: constant.FIREBASE_PUSH_KEY })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          localStorage.setItem("deviceid", currentToken);
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  }
};
