// let
var baseUrl = `https://api.oma.swiftoffice.org/api`;
var redirectUrl = `https://oma.swiftoffice.org/portal/login`;
if (process.env.REACT_APP_ENV == "development") {
  baseUrl = "http://localhost:8080/api";  
  redirectUrl = "http://localhost:3000/portal/login";
}
console.log(baseUrl + " redirect: " + redirectUrl);
const commonConfigs = {
  authority:
    "https://login.microsoftonline.com/482e292f-548d-4e7d-b44c-1cdb1a86cb49",
  appId: "a08e5d4b-541b-49fb-986d-73f5f603a544",
  scopes: ["user.read", "calendars.read"],

  redirectUrl: redirectUrl,
  // redirectUrl: "",
  apiHeader: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
  apiUrls: {
    login: () => `${baseUrl}/portal/auth/login`,
    tokenExpiry: () => `${baseUrl}/portal/auth/expiryCheck`,
    getRoleDropdown: () => `${baseUrl}/portal/common/getRole`,
    getBaseDropdown: () => `${baseUrl}/portal/common/getBase`,
    getRightsDropdown: () => `${baseUrl}/portal/common/getRights`,
    getCountryDropdown: () => `${baseUrl}/portal/common/getCountry`,
    getMascotDropdown: () => `${baseUrl}/portal/common/getMascot`,
    getDetachmentTypeDropdown: () =>
      `${baseUrl}/portal/common/getDetachmentType`,
    getAllCountriesDropdown: () => `${baseUrl}/portal/common/getAllCountries`,
    getStatesByCountry: (code) =>
      `${baseUrl}/country/getStatesByCountry/${code}`,
    getCityByStateCountry: (code, state) =>
      `${baseUrl}/country/getCityByStateCountry/${code}/${state}`,
    getMultiBase: () => `${baseUrl}/portal/common/getMultiBase`,
    getMultiCountry: () => `${baseUrl}/portal/common/getMultiCountry`,
    getMultiDetType: () => `${baseUrl}/portal/common/getMultiDetType`,
    getMultiCountryDet: () => `${baseUrl}/portal/common/getMultiCountryDet`,
    getMultiBaseDet: () => `${baseUrl}/portal/common/getMultiBaseDet`,

    getMultiCity: () => `${baseUrl}/portal/common/getMultiCity`,
    getMultiMoveCountry: () => `${baseUrl}/portal/common/getMultiMoveCountry`,
    getMultiRank: () => `${baseUrl}/portal/common/getMultiRank`,
    getMultiState: () => `${baseUrl}/portal/common/getMultiState`,
    getMultiMascot: () => `${baseUrl}/portal/common/getMultiMascot`,
    uploadToAWS: (filename) =>  `${baseUrl}/portal/common/uploadFile/${filename}`,

    //FILTERED
    getMultiStateFilter: () => `${baseUrl}/portal/common/getMultiStateFilter`,
    getMultiCitiesFilter: () => `${baseUrl}/portal/common/getMultiCitiesFilter`,
    getMultiBaseFilters: () => `${baseUrl}/portal/common/getMultiBaseFilters`,
    getMultiMascotFilters: () =>
      `${baseUrl}/portal/common/getMultiMascotFilters`,
    getMultiBaseDetFilters: () =>
      `${baseUrl}/portal/common/getMultiBaseDetFilters`,

    //MOVEMENT
    getSubmissions: () => `${baseUrl}/portal/move/getSubmissions`,
    deleteSubmission: (id) => `${baseUrl}/portal/move/deleteSubmission/${id}`,

    //BROADCAST
    createBroadcast: () => `${baseUrl}/portal/move/createBroadcast`,
    getBroadcast: (id) => `${baseUrl}/portal/move/getBroadcast/${id}`,
    getChatUsers: (id, baseid, pid) =>
      `${baseUrl}/portal/move/getChatUsers/${id}/${baseid}/${pid}`,
    getChatConvo: (id) => `${baseUrl}/portal/move/getChatConvo/${id}`,
    updateLastRead: (id, personnelid) =>
      `${baseUrl}/portal/move/updateLastRead/${id}/${personnelid}`,

    submitMessage: () => `${baseUrl}/portal/move/submitMessage`,

    //DETACHMENT
    getDetachment: () => `${baseUrl}/portal/det/getDetachment`,
    getFilterDetachment: () => `${baseUrl}/portal/det/getFilterDetachment`,

    getUser: (id) => `${baseUrl}/portal/user/getUser/${id}`,
    updateUser: () => `${baseUrl}/portal/user/updateUser`,

    //CONFIG COUNTRY
    getCountry: () => `${baseUrl}/portal/config/country/getCountry`,
    createCountry: () => `${baseUrl}/portal/config/country/createCountry`,
    deleteCountry: (id) =>
      `${baseUrl}/portal/config/country/deleteCountry/${id}`,

    //CONFIG BASE
    getBase: () => `${baseUrl}/portal/config/base/getBase`,
    createBase: () => `${baseUrl}/portal/config/base/createBase`,
    updateBase: () => `${baseUrl}/portal/config/base/updateBase`,
    deleteBase: (id) => `${baseUrl}/portal/config/base/deleteBase/${id}`,

    //CONFIG MASCOT
    getMascot: () => `${baseUrl}/portal/config/mascot/getMascot`,
    createMascot: () => `${baseUrl}/portal/config/mascot/createMascot`,
    updateMascot: () => `${baseUrl}/portal/config/mascot/updateMascot`,
    deleteMascot: (id) => `${baseUrl}/portal/config/mascot/deleteMascot/${id}`,

    //CONFIG SUBMISSION
    getSubmission: (mascotid) =>
      `${baseUrl}/portal/config/submission/getSubmission/${mascotid}`,
    createSubmission: () =>
      `${baseUrl}/portal/config/submission/createSubmission`,
    updateSubmission: () =>
      `${baseUrl}/portal/config/submission/updateSubmission`,
    deleteSubmission: (id) =>
      `${baseUrl}/portal/config/submission/deleteSubmission/${id}`,

    //CONFIG COMMON REPLIES
    getCommonReply: () => `${baseUrl}/portal/config/reply/getCommonReply`,
    createCommonReply: () => `${baseUrl}/portal/config/reply/createCommonReply`,
    updateCommonReply: () => `${baseUrl}/portal/config/reply/updateCommonReply`,
    deleteCommonReply: (id) =>
      `${baseUrl}/portal/config/reply/deleteCommonReply/${id}`,

    //CONFIG MISSION
    getMission: (id) => `${baseUrl}/portal/config/mission/getMission/${id}`,
    createMission: () => `${baseUrl}/portal/config/mission/createMission`,
    updateMission: () => `${baseUrl}/portal/config/mission/updateMission`,
    deleteMission: (id) =>
      `${baseUrl}/portal/config/mission/deleteMission/${id}`,
  },
};
// const configs = Object.assign(commonConfigs, envConfigs);
export default commonConfigs;
