import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "./common/assets/scss/style.scss";
import PrivateRoute from "./auth_module/routing_module/PrivateRoute";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { getToken } from "./firebase";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./common/containers/TheLayout"));

//AUTH PAGES
const Login = React.lazy(() => import("./auth_module/login/LoginScreen"));

//RESULT PAGES
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// function FallbackComponent() {
//   alert("An error has occured.");
//   return <></>;
// }
// const myFallback = <FallbackComponent />;
function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  getToken(setTokenFound);
  document.title = "Overseas Movement Web";
  return (
    // <Sentry.ErrorBoundary fallback={myFallback}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {/* <PublicRoute restricted = {false} exact path="/" name="Landing Page" component={props => <Landing {...props}/>} /> */}

            {/** AUTH ROUTES*/}
            <Route
              restricted={false}
              exact
              path="/portal/login"
              name="Login Page"
              component={(props) => <Login {...props} />}
            />

            {/** STATUS HANDLING*/}
            <Route
              exact
              path="/portal/404"
              name="Page 404"
              component={(props) => <Page404 {...props} />}
            />

            <Route
              exact
              path="/portal/500"
              name="Page 500"
              component={(props) => <Page500 {...props} />}
            />

            <PrivateRoute
              path="/portal"
              name="Home"
              component={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    // </Sentry.ErrorBoundary>
  );
}

export default App;
