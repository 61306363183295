export const NOT_SELECT_MESSAGE = "Mandatory Field(s) Not Selected.";
export const INVALID_INPUT = "Input is incorrect. Please try again.";
export const DATE_INPUT = "Date cannot be lesser than current date.";
export const MULTI_SELECT_VALIDATION_MESSAGE =
  "At least one field(s) must be selected.";
export const ACCESS_SUPER_ADMIN = 1;
export const ACCESS_MASCOT_ADMIN = 2;

export const FIREBASE_PUSH_KEY =
  "BG_3-i5Q_sE3UdfuhwobgVOYdFnbTqFspkNLzrgH1Gd6vDW8Y9O7Ccsd-z2wLjY-kSTnn-6s3M3ctUkyRbZBLUY";

export const ACCOUNT_STATUS = [
  {
    id: 0,
    value: "Inactive",
  },
  {
    id: 1,
    value: "Active",
  },
];

export const SUBMISSION_TYPE = [
  {
    id: 1,
    value: "Overseas Leave",
  },
  {
    id: 2,
    value: "Overseas Duty",
  },
  {
    id: 3,
    value: "Overseas Studies",
  },
  {
    id: 4,
    value: "Overseas Unpaid Leave",
  },
  {
    id: 5,
    value: "Overseas Det/Exercises",
  },
];