import commonConfigs from '../../config';
import axios from 'axios';

export const isLogin = async() => {
    var status = false;
    if (localStorage.getItem('accessToken')) {
        await axios.get(commonConfigs.apiUrls.tokenExpiry(),{headers: commonConfigs.apiHeader}).then(res => {
            status = true;
            return status;
            }).catch(err =>{
                status = false;
                alert('Your session has expired. Please Login again.')
                window.location = '/portal/login'
                return status;
            });
            //console.log(status);
            return status;
        }
    else if(!localStorage.getItem('accessToken')){
        status = false;
        alert('You are not authenticated. Please Login.')
        window.location = '/portal/login'
        return status;

    }else{
        status = false;
        alert('You are not authenticated. Please Login.')
        window.location = '/portal/login'
        return status;

    }
}